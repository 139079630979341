import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import { Box, Button, IconButton } from "@material-ui/core";
import TextField from "../../../../components/main/CustomFields/TextField";
import { ReactComponent as LibraryBooksIcon } from '../../../../assets/icons/library_books.svg';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_red.svg';
import { deleteLibraryEmails, duplicateLibraryEmails } from "../../../../redux/services/my-library";
import { enqueueSnackbar } from "notistack";
import ConfirmationModal from "../../../../components/main/ConfirmationModal";

import { useStyles } from "./styles";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';

const ContentEditor = ({
  campaign,
  email,
  editorState,
  setEmail,
  isSaveDisabled,
  handleSaveEmail,
  handleGetEmails,
  setEditorState,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();

  const handleDeleteEmail = async () => {
    setShowDeleteModal(false);

    const { error } = await deleteLibraryEmails({ campaignId: campaign.id, emailId: email.id });

    if (!error) {
      setEmail(null);
      handleGetEmails(campaign.id, null);

      enqueueSnackbar(
        'Email deleted successfully',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Failed to delete',
        { variant: 'error' }
      )
    }
  }

  const handleDuplicateEmail = async () => {
    const { data, error } = await duplicateLibraryEmails({ campaignId: campaign.id, emailId: email.id });

    if (data && !error) {
      handleGetEmails(data.campaignId, data.id);

      enqueueSnackbar(
        'Duplicated successfully',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Failed to duplicate',
        { variant: 'error' }
      )
    }
  }

  return (
    <>
      <Box className={classes.content}>
        <TextField
          value={email.subject}
          placeholder={'Subject line'}
          label={'Subject line'}
          error={!email?.subject?.length ? 'This field is required' : ''}
          onChange={(event) => setEmail({ ...email, subject: event.target.value })}
        />
        <Box className={classes.editor}>
          {/* <TextField
            // inputRef={inputRef}
            value={email.content}
            placeholder={'Message'}
            type={'textarea'}
            minRows={22}
            className={classes.textarea}
            error={!email?.content?.length ? 'This field is required' : ''}
            onChange={(event) => setEmail({ ...email, content: event.target.value })}
          // onFocus={() => setFocused(true)}
          // onBlur={() => setFocused(false)}
          /> */}

          {editorState && (
            <Editor
              editorState={editorState}
              // contentState={{}}
              toolbar={{
                options: ['blockType', 'fontSize', 'inline', 'list', 'textAlign', 'link', 'image', 'remove', 'history'],
                classname: classes.toolbarButton,
                textAlign: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['left', 'center', 'right'],
                },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['ordered', 'unordered'],
                },
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                  bold: { className: undefined },
                  italic: { className: undefined },
                  underline: { className: undefined },
                  strikethrough: { className: undefined },
                },
              }}
              handleReturn={(event) => {
                // Check if Shift key is pressed along with Enter
                if (event.shiftKey) {
                  // Prevent the default action (inserting a line break)
                  return 'handled';
                }

                return 'not-handled';
              }}
              handlePastedText={(text, html, editorState) => {}}
              onEditorStateChange={(event) => setEditorState(event)}
              wrapperClassName={`wrapper-class ${classes.editorWrapper}`}
              toolbarClassName={`toolbar-class ${classes.editorToolbar}`}
              editorClassName={`editor-class ${classes.editorTextarea}`}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Box className={classes.actions}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSaveDisabled()}
            endIcon={<LibraryBooksIcon style={{ color: '#3A84C9' }} />}
            onClick={() => handleSaveEmail()}
          >
            Save
          </Button>
          <IconButton onClick={() => handleDuplicateEmail()}>
            <DuplicateIcon style={{ color: 'black' }} />
          </IconButton>
          {/* <IconButton>
            <ReloadIcon style={{ color: 'black' }} />
          </IconButton> */}
          <Box className={classes.varticalSeparator} />
          <IconButton
            disabled={campaign?.emails?.length <= 1}
            onClick={() => setShowDeleteModal(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      <ConfirmationModal
        open={showDeleteModal}
        title={"Are you sure you want to delete this email?"}
        description={"This action is irreversible"}
        onClose={() => setShowDeleteModal(false)}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteEmail}
      />
    </>
  );
};

export default ContentEditor;
