import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { ReactComponent as InvoiceIcon } from '../../../../../../../assets/icons/invoice_icon.svg';
import moment from "moment";
import { getPaymentsHistory } from '../../../../../../../redux/services/subscriptions';

import { useStyles } from './styles';

interface Props {
  setTab: (tab: string) => void;
}

const PaymentsHistory: React.FC<Props> = ({
  setTab,
}) => {
  const [history, setHistory] = useState<any>(null);

  const classes = useStyles();

  useEffect(() => {
    handleGetPaymentsHistory();
  }, []);

  const handleGetPaymentsHistory = async () => {
    const { data, error } = await getPaymentsHistory();

    if (data && !error) {
      setHistory([
        ...data,
        // {
        //   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //   "timestamp": "2024-08-19T07:25:07.647Z",
        //   "lastFour": "5709"
        // }
      ])
    }
  }

  return (
    <>
      <Typography style={{ marginBottom: '16px', fontSize: '30px', fontWeight: '700', lineHeight: '32px', textAlign: 'center' }}>
        Payment history
      </Typography>
      <Box className={classes.historyContainer}>
        {!history
          ? <CircularProgress 
              color="inherit"
              style={{
                margin: '40px auto'
              }}
            />
          : history.length
              ? history.map((item, index) => (
                  <Box 
                    key={index} 
                    className={classes.historyItem}
                  >
                    <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
                      {moment(item.timestamp).format('DD-MMM-YYYY')}
                    </Typography>
                    <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                      XXXX XXXX XXXX {item.lastFour}
                    </Typography>
                    {/* <Button
                      disabled
                      variant='text'
                      style={{ color: '#3A84C9' }}
                    >
                      <InvoiceIcon />&nbsp;
                      See receipt
                    </Button> */}
                  </Box>
                ))
              : <Typography style={{ textAlign: 'center', margin: '20px 0px' }}>
                  Not found
                </Typography>
        }
      </Box>
      {history && (
        <Button
          color='primary'
          variant='contained'
          style={{ width: '160px', margin: '0 auto' }}
          onClick={() => setTab('currentPlan')}
        >
          Done
        </Button>
      )}
    </>
  );
};

export default PaymentsHistory;
