import React, { useEffect, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ReactComponent as RegenerateChatMessageIcon } from '../../../../assets/icons/regenerate_copilot_message.svg';
import { ReactComponent as CopyChatMessageIcon } from '../../../../assets/icons/copy_chat_message_icon.svg';
import { ReactComponent as LikeChatMessageIcon } from '../../../../assets/icons/like_copilot_message.svg';
import { ReactComponent as DislikeChatMessageIcon } from '../../../../assets/icons/dislike_copilot_message.svg';

import { useStyles } from "./styles";
import { enqueueSnackbar } from "notistack";
import { likeMessage, dislikeMessage } from "../../../../redux/services/copilot";
import Markdown from "react-markdown";

const TypingText = ({ 
  id,
  chat,
  text, 
  speed = 150, 
  author,
  answerStatus,
  allowRefetch,
  allowAnimate, 
  scrollToBottom,
  setChat
}) => {
  const [displayedText, setDisplayedText] = useState('');

  const classes = useStyles();
  
  useEffect(() => {
    if (author === 'user' || !allowAnimate) {
      setDisplayedText(text);

      scrollToBottom();
    } else {
      if (text.length) {
        let index = 0;
    
        const interval = setInterval(() => {
          setDisplayedText((prev) => prev + text.charAt(index));
          
          index++;
  
          if (index === text.length) {
            clearInterval(interval);
          }
  
          scrollToBottom();
        }, speed);
  
        return () => clearInterval(interval);
      }
    }
  }, [text, speed, author]);

  const handleCopyMesssage = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        enqueueSnackbar(
          'Copied to the clipboard',
          { variant: 'info' }
        )
      })
      .catch(() => {
        enqueueSnackbar(
          'Failed to copy to the clipboard',
          { variant: 'error' }
        )
      });
  }

  const handleLikeMessage = async () => {
    const { error } = await likeMessage({ sessionId: chat.sessionId, messageId: id, });

    if (!error) {
      setChat({
        ...chat,
        messages: chat.messages.map(item => (
          item.id === id
            ? {
                ...item,
                answerStatus: 'LIKED'
              }
            : item
        ))
      })

      enqueueSnackbar(
        'Message liked',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Message like failed',
        { variant: 'error' }
      )
    }
  }

  const handleDislikeMessage = async () => {
    const { error } = await dislikeMessage({ sessionId: chat.sessionId, messageId: id, });

    if (!error) {
      setChat({
        ...chat,
        messages: chat.messages.map(item => (
          item.id === id
            ? {
                ...item,
                answerStatus: 'DISLIKED'
              }
            : item
        ))
      })

      enqueueSnackbar(
        'Message disliked',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Message dislike failed',
        { variant: 'error' }
      )
    }
  }

  const handleRegenerateMessage = () => {
    
  }

  return (
    <Box style={{ width: '100%' }}>
      <Typography className={classes.message}>
        {!!displayedText.length
          ? <Markdown
              className={classes.markdown}
            >
              {displayedText}
            </Markdown>
          : <Box className={classes.progressBar} />
        }
      </Typography>
      {!!displayedText.length && (
        <Box className={classes.actionsBox}>
          {author === 'copilot' && allowRefetch && (
            <IconButton 
              size="small"
              onClick={() => handleRegenerateMessage()}
            >
              <RegenerateChatMessageIcon />
            </IconButton>
          )}
                  
          <IconButton 
            size="small"
            onClick={() => handleCopyMesssage()}
          >
            <Tooltip
              arrow
              enterDelay={1000}
              title={'Copy'}
              placement="bottom"
            >
              <CopyChatMessageIcon />
            </Tooltip>
          </IconButton>

          {author === 'copilot' && answerStatus && (
            <>
              <Box className={classes.verticalSeparator} />
              {(answerStatus === 'LIKED' || answerStatus === 'UNEVALUATED') && (
                <IconButton 
                  size="small"
                  // disabled={answerStatus === 'LIKED'}
                  onClick={() => {
                    if (answerStatus === 'UNEVALUATED') {
                      handleLikeMessage()
                    }
                  }}
                  style={{
                    opacity: answerStatus === 'LIKED' ? '0.5' : '1',
                    // pointerEvents: answerStatus === 'LIKED' ? 'none' : 'all',
                  }}
                >
                  <Tooltip
                    arrow
                    enterDelay={1000}
                    title={
                      answerStatus === 'LIKED'
                        ? 'You have liked this response'
                        : 'Good response'
                    }
                    placement="bottom"
                  >
                    <LikeChatMessageIcon />
                  </Tooltip>
                </IconButton>
              )}
              {(answerStatus === 'DISLIKED' || answerStatus === 'UNEVALUATED') && (
                <IconButton 
                  size="small"
                  // disabled={answerStatus === 'DISLIKED'}
                  onClick={() => {
                    if (answerStatus === 'UNEVALUATED') {
                      handleDislikeMessage()
                    }
                  }}
                  style={{
                    opacity: answerStatus === 'DISLIKED' ? '0.5' : '1',
                    // pointerEvents: answerStatus === 'DISLIKED' ? 'none' : 'all',
                  }}
                >
                  <Tooltip
                    arrow
                    enterDelay={1000}
                    title={
                      answerStatus === 'DISLIKED'
                        ? 'You have disliked this response'
                        : 'Bad response'
                    }
                    placement="bottom"
                  >
                    <DislikeChatMessageIcon />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TypingText;
