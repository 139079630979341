// observableService.js
import { Subject } from 'rxjs';

// Create a subject to broadcast events
const eventSubject = new Subject();

// Export functions to subscribe and emit events
export const observableService = {
  sendEvent: (data) => eventSubject.next(data),  // Trigger the event
  onEvent: () => eventSubject.asObservable(),   // Subscribe to the event
};