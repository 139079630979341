import { BlockComponent } from "../../../framework/main/BlockComponent";
import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import { runEngine } from "../../../framework/main/RunEngine";
import MessageEnum from "../../../framework/main/Messages/MessageEnum";

import { WithStyles } from "@material-ui/core";
import { getName } from "../../../framework/main/Messages/MessageEnum";
import StorageProvider from "../../../framework/main/StorageProvider";
import { getLibraryCampaigns } from "../../../redux/services/my-library";
import { getOnboardingCompleteness } from "../../../redux/services/onboarding";
import { getUrlAndParams, removeUrlParams } from "../../../helpers/other";
import { getCurrentSubscription } from "../../../redux/services/subscriptions";
import { getCurrentSubscriptionStatus } from "../../../helpers/subscription";
import { observableService } from "../../../services/observable";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  history: any;
  navigation: any;
  id: string;
  token?: string;
}

interface S {
  rightMenu: boolean;
  myLibraries: any;
  showActivateLicense: boolean,
  showRenewLicense: boolean,
  showCompleteOnboardingBaner: boolean,
  showCompleteOnboardingModal: boolean;
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<Props, S, SS> {
  apiAllGetMyLibraryCallId: string = "";
  apiAllGetMyrWorkflowsCallId: string = "";
  timer: any = null;
  token: string;
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      rightMenu: false,
      myLibraries: [],
      showActivateLicense: false,
      showRenewLicense: false,
      showCompleteOnboardingBaner: false,
      showCompleteOnboardingModal: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallIds = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    }
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    const { params } = getUrlAndParams();
    const afterSkipOnboarding = params.get('afterSkipOnboarding');

    if (!!afterSkipOnboarding) {
      this.setState({
        showCompleteOnboardingModal: true
      })

      removeUrlParams();
    }

    this.fetchMyLibrary();
    this.handleGetOnboardingCompleteness();
    this.handleGetSubscriptionActive();

    this.subscription = observableService.onEvent().subscribe((data) => {
      if (data === 'Check if subscription is active') {
        this.handleGetSubscriptionActive();
      }
    });
  }

  async componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleCloseCompleteOnboardingModal = () => {
    this.setState({ showCompleteOnboardingModal: false });
  }

  handleRightMenu = () => {
    this.setState({ rightMenu: !this.state.rightMenu });
  };

  handleRedirectChatHistory = () => {
    this.props.navigation.navigate(`/copilot`);
  };

  handleRedirectLibrary = (id: number | string) => {
    if (id) {
      this.props.navigation.navigate(`/tactical-outreach/email?campaign_id=${id}`);
    } else {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "MyLibrary"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  };

  handleRedirectWorkFlow = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Myworkflow");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleRedirectTactical = (tab: number) => {
    this.props.navigation.navigate(`/tactical-outreach?tab=${tab}`)
  };

  handleGetSubscriptionActive = async () => {
    const { data, error } = await getCurrentSubscription();

    if (data && !error) {
      this.setState({
        showActivateLicense: getCurrentSubscriptionStatus(data) === 'EXPIRED',
        showRenewLicense: getCurrentSubscriptionStatus(data) === 'CANCELED'
      });
    } else {
      this.setState({
        showActivateLicense: true,
        showRenewLicense: false
      });
    }
  }

  handleGetOnboardingCompleteness = async () => {
    const { data, error } = await getOnboardingCompleteness();

    if (data && !error) {
      this.setState({
        showCompleteOnboardingBaner: Object.values(data).some(item => !item)
      });
    } else {
      this.setState({
        showCompleteOnboardingBaner: true
      });
    }
  }

  fetchMyLibrary = async () => {
    const { data, error } = await getLibraryCampaigns();

    if (data && !error) {
      this.setState({
        myLibraries: data
      })
    }

    // const header = {
    //   "Content-Type": configJSON.validationApiContentType,
    //   token: this.token,
    // };
    // const requestMessage = new Message(
    //   getName(MessageEnum.RestAPIRequestMessage)
    // );

    // this.apiAllGetMyLibraryCallId = requestMessage.messageId;
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceEndPointMessage),
    //   configJSON.fetchMyLibaray
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   configJSON.validationApiMethodType
    // );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestHeaderMessage),
    //   JSON.stringify(header)
    // );

    // runEngine.sendMessage(requestMessage.id, requestMessage);

    // return true;
  };

  handelColorInMyWork = (emailType?: number) => {
    if (emailType === 3) {
      return "#3A84C9";
    }
    if (emailType === 2) {
      return "#D93855";
    }
    return "#059669";
  }

  handelColorInMyLibrary = (unique_id: number, condition1: any, condition2: any, condition3: any) => {
    switch (unique_id) {
      case 3:
        return condition1;
      case 2:
        return condition2;
      default:
        return condition3
    }
  }

  handleWorkFlowsCreation = (id?: number) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Cfdataintegrationviapromptengineering4");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      paramGroupId: id,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleEmailCreationMyLibrary = (id: number, campType: string, param_group_id: number) => {
    StorageProvider.setItem("param_group_id", `${param_group_id}`)
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailCreation");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      campaignId: id,
      campaignType: campType
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

}
